<template>
  <div class='advertisers-facts'>
    <div class="container">
      <h2 class="title">{{$t("factsTitle")}}</h2>

      <div class="advertisers-facts__items">
        <div class="advertisers-facts__item" v-for="(fact,index) in $t('facts')" :key="index">
          <div class="advertisers-facts__number">{{fact.fact}}</div>
          <div class="advertisers-facts__descr">{{fact.content}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AdvertisersFacts'
  }
</script>